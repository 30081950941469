<template>
  <div
    :class="['app-pages', {
      'app-pages--loaded': loaded,
      [`theme--${ctx?.brand}`]: ctx?.brand
    }]"
  >
    <div
      v-if="!isCustomClpPage"
      class="noprint"
    >
      <fragment
        :headers="headerFragment.headers as Record<string, string>"
        :timeout="10000"
        :url="headerFragment.url"
        render-mode="server"
      />
    </div>

    <main
      ref="main"
      :class="{ 'custom-clp-page': isCustomClpPage }"
    >
      <slot />
    </main>

    <div
      v-if="!isCustomClpPage"
      class="noprint"
    >
      <fragment
        :headers="footerFragment.headers as Record<string, string>"
        :timeout="10000"
        :url="footerFragment.url"
        render-mode="server"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent, onBeforeUnmount, onMounted, ref } from 'vue'
  import { getFragment } from '../../composables/get-fragment'
  import { setCookie } from '../../utils/cookies/set-cookie'
  import { gclidDebug } from '../../utils/gclid-debug'

  const Fragment = defineAsyncComponent(async () => await import('@ocp-zmarta/zsc-plugin-vite-ssr/vue').then(m => m.Fragment))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      vertical: getServerState('vertical'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp'),
      features: getServerState('features'),
      path: getServerState('path')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const loaded = ref(true)
  const showFooter = ref(false)
  const hero = ref<HTMLElement | null>(null)
  const heroObserver = ref<IntersectionObserver | null>(null)

  const showTranslations = computed(() => getServerState('vertical')?.toString() === 'cl')
  const showStickyCta = computed(() => getServerState('vertical')?.toString() === 'cl')

  const headerFragment = computed(() => {
    return getFragment({
      name: 'siteNavigation',
      path: '/header',
      query: { show_translations: showTranslations.value },
      ctx
    })
  })

  const footerFragment = computed(() => {
    return getFragment({
      name: 'siteNavigation',
      path: '/footer',
      query: { sticky_cta_spacing: showStickyCta.value },
      ctx
    })
  })

  const isCustomClpPage = computed(() => {
    return ['/clp/lan/custom', '/clp/forsakring/custom'].some(path => ctx?.path?.startsWith(path))
  })

  function setupHeroObserver () {
    if (!('IntersectionObserver' in window)) return

    hero.value = document.querySelector('.zg-hero')

    if (!hero.value) {
      showFooter.value = true
      return
    }

    heroObserver.value = new IntersectionObserver(([entry]) => {
      if (!showFooter.value && !entry.isIntersecting) {
        showFooter.value = true
      }
    })

    heroObserver.value?.observe(hero.value)
  }

  onMounted(() => {
    setCookie({ name: 'locale', value: ctx?.locale })
    setCookie({ name: 'op_user_id', value: ctx?.userId, type: 'op_user_id' })
    setupHeroObserver()
    void gclidDebug()
  })

  onBeforeUnmount(() => {
    heroObserver.value?.disconnect()
  })
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 3.5rem;
  }

  .custom-clp-page {
    padding-top: 0;
  }

  @media print {
    .noprint {
      display: none;
    }

    main {
      padding-top: 0;
    }
  }

  // For some reason the background-color is not set on this class??
  :deep(.zg-section-bg-color--beige) {
    background-color: var(--beige);
  }
</style>
