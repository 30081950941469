import type { Page } from '@ocp-zmarta/content-sdk'
import type { useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import type { Ref } from 'vue'

export function getFragmentQuery (ctx: NonNullable<ReturnType<typeof useSSRContext>>, pageData: Ref<Page | undefined>): object {
  return {
    locale: ctx?.locale,
    op_user_id: ctx?.userId,
    ip: ctx?.userIp,
    ...(ctx?.referer ? { referer: ctx?.referer } : {})
  }
}
